var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getServiceInfosErrors.step),callback:function ($$v) {_vm.$set(_vm.getServiceInfosErrors, "step", $$v)},expression:"getServiceInfosErrors.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getServiceInfosErrors.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('service-info:basicInfo')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service-info:basicInfo')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"error-messages":_vm.getServiceInfosErrors.stepOne
                                                .fields.title,"label":`${_vm.$t(
                                            'service-info:informationTitle'
                                        )}*`},model:{value:(_vm.getServiceInfosModal.title),callback:function ($$v) {_vm.$set(_vm.getServiceInfosModal, "title", $$v)},expression:"getServiceInfosModal.title"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"float-right",staticStyle:{"width":"65%"},attrs:{"clearable":"","label":`${_vm.$t(
                                                    'service-info:archiveDate'
                                                )}`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getServiceInfosModal.archiveDate = null}},model:{value:(
                                                    _vm.getServiceInfosModal.archiveDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceInfosModal, "archiveDate", $$v)},expression:"\n                                                    getServiceInfosModal.archiveDate\n                                                "}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{on:{"input":function($event){_vm.startDate = false}},model:{value:(
                                                _vm.getServiceInfosModal.archiveDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getServiceInfosModal, "archiveDate", $$v)},expression:"\n                                                getServiceInfosModal.archiveDate\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"box-autocomplete",attrs:{"data-cy":"assignTo","label":_vm.$t('service-info:responsible'),"item-value":"_id","items":_vm.getUsersList,"return-object":"","multiple":"","filter":_vm.filter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(` ${data.item.name} ${data.item.lastname},`)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"mx-2",attrs:{"size":"35","color":"secondary"}},[(
                                                            !data.item
                                                                .picture
                                                        )?_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(_vm.avatarNick( data.item .name, data.item .lastname ))+" ")]):_c('img',{attrs:{"src":`${data.item.picture}`}})])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.lastname)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.job)+" ")])],1)]}}]),model:{value:(
                                            _vm.getServiceInfosModal.responsible
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceInfosModal, "responsible", $$v)},expression:"\n                                            getServiceInfosModal.responsible\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"float-right pt-4",staticStyle:{"width":"65%"},attrs:{"items":_vm.getRegistryDictionaries.priorityType
                                                ? _vm.getRegistryDictionaries.priorityType
                                                : [],"dense":"","label":_vm.$t('service-info:priority'),"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getServiceInfosModal.priority
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceInfosModal, "priority", $$v)},expression:"\n                                            getServiceInfosModal.priority\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"textGrey--text",attrs:{"small":"","elevation":"0","color":"white"},on:{"click":function($event){return _vm.toMe()}}},on),[_c('v-icon',{attrs:{"color":"textGrey","size":"35"}},[_vm._v("mdi-account")]),_vm._v(_vm._s(_vm.$t('service-info:toMe')))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('service-info:toMe')))])])],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"textGrey--text",attrs:{"small":"","elevation":"0","color":"white"},on:{"click":function($event){return _vm.toAllUsers()}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"textGrey","size":"35"}},[_vm._v("mdi-account-group")]),_vm._v(_vm._s(_vm.$t( 'service-info:allUsers' )))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('service-info:allUsers')))])])],1),_c('v-col',{attrs:{"cols":"12","offset":"1","md":"7"}},[_c('v-autocomplete',{staticClass:"box-autocomplete",attrs:{"data-cy":"assignTo","label":_vm.$t('service-info:infoTo') + '*',"item-value":"_id","items":_vm.value === 'users'
                                                ? _vm.getUsersList
                                                : _vm.getAllDepartments,"error-messages":_vm.getServiceInfosErrors.stepOne
                                                .fields.to,"return-object":"","multiple":"","filter":_vm.filter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(_vm.value === 'users')?_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(` ${data.item.name} ${data.item.lastname},`)+" ")]):_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(`${data.item.name},`)+" ")])]}},{key:"item",fn:function(data){return [(_vm.value === 'users')?_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"mx-2",attrs:{"size":"35","color":"secondary"}},[(
                                                            !data.item
                                                                .picture
                                                        )?_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(_vm.avatarNick( data.item .name, data.item .lastname ))+" ")]):_c('img',{attrs:{"src":`${data.item.picture}`}})])],1):_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"35","color":"#445D75"}},[_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(data.item.shortName.toUpperCase())+" ")])])],1),(_vm.value === 'users')?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.lastname)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.job)+" ")])],1):_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}}]),model:{value:(
                                            _vm.getServiceInfosModal.to[_vm.value]
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceInfosModal.to, _vm.value, $$v)},expression:"\n                                            getServiceInfosModal.to[value]\n                                        "}},[_c('v-tabs',{staticClass:"elevation-1 select--fields",attrs:{"slot":"prepend-item","centered":""},slot:"prepend-item"},[_c('v-tab',{on:{"click":function($event){_vm.value = 'users'}}},[_vm._v(_vm._s(_vm.$t('service-info:users')))]),_c('v-tab',{on:{"click":function($event){_vm.value = 'departments'}}},[_vm._v(_vm._s(_vm.$t( 'service-info:departments' )))])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'service-info:info'
                                        )}*`,"rows":"1","auto-grow":"","no-resize":"","error-messages":_vm.getServiceInfosErrors.stepOne
                                                .fields.info},model:{value:(_vm.getServiceInfosModal.info),callback:function ($$v) {_vm.$set(_vm.getServiceInfosModal, "info", $$v)},expression:"getServiceInfosModal.info"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }