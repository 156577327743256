<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getServiceInfosErrors.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getServiceInfosErrors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('service-info:basicInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('service-info:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6"
                                        ><v-text-field
                                            v-model="getServiceInfosModal.title"
                                            :error-messages="
                                                getServiceInfosErrors.stepOne
                                                    .fields.title
                                            "
                                            :label="`${$t(
                                                'service-info:informationTitle'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    class="float-right"
                                                    style="width: 65%;"
                                                    v-model="
                                                        getServiceInfosModal.archiveDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getServiceInfosModal.archiveDate = null
                                                    "
                                                    :label="`${$t(
                                                        'service-info:archiveDate'
                                                    )}`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getServiceInfosModal.archiveDate
                                                "
                                                @input="startDate = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            data-cy="assignTo"
                                            :label="
                                                $t('service-info:responsible')
                                            "
                                            item-value="_id"
                                            :items="getUsersList"
                                            return-object
                                            v-model="
                                                getServiceInfosModal.responsible
                                            "
                                            multiple
                                            class="box-autocomplete"
                                            :filter="filter"
                                        >
                                            <template v-slot:selection="data">
                                                <div class="mr-1">
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname},`
                                                    }}
                                                </div>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar>
                                                    <v-avatar
                                                        size="35"
                                                        color="secondary"
                                                        class="mx-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                !data.item
                                                                    .picture
                                                            "
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                avatarNick(
                                                                    data.item
                                                                        .name,
                                                                    data.item
                                                                        .lastname
                                                                )
                                                            }}
                                                        </span>
                                                        <img
                                                            :src="`${data.item.picture}`"
                                                            v-else
                                                        />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <!-- === === CONTENT === === -->
                                                <!-- === USERS ===  -->
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ data.item.lastname }}
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.job }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            class="float-right pt-4"
                                            style="width: 65%;"
                                            v-model="
                                                getServiceInfosModal.priority
                                            "
                                            :items="
                                                getRegistryDictionaries.priorityType
                                                    ? getRegistryDictionaries.priorityType
                                                    : []
                                            "
                                            dense
                                            :label="$t('service-info:priority')"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="toMe()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        size="35"
                                                        >mdi-account</v-icon
                                                    >{{
                                                        $t('service-info:toMe')
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{
                                                $t('service-info:toMe')
                                            }}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    v-on="on"
                                                    elevation="0"
                                                    @click="toAllUsers()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        class="mr-2"
                                                        size="35"
                                                        >mdi-account-group</v-icon
                                                    >{{
                                                        $t(
                                                            'service-info:allUsers'
                                                        )
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{
                                                $t('service-info:allUsers')
                                            }}</span>
                                        </v-tooltip></v-col
                                    >

                                    <v-col cols="12" offset="1" md="7">
                                        <v-autocomplete
                                            data-cy="assignTo"
                                            :label="
                                                $t('service-info:infoTo') + '*'
                                            "
                                            item-value="_id"
                                            :items="
                                                value === 'users'
                                                    ? getUsersList
                                                    : getAllDepartments
                                            "
                                            :error-messages="
                                                getServiceInfosErrors.stepOne
                                                    .fields.to
                                            "
                                            return-object
                                            v-model="
                                                getServiceInfosModal.to[value]
                                            "
                                            multiple
                                            class="box-autocomplete"
                                            :filter="filter"
                                        >
                                            <template v-slot:selection="data">
                                                <div
                                                    v-if="value === 'users'"
                                                    class="mr-1"
                                                >
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname},`
                                                    }}
                                                </div>
                                                <div class="mr-1" v-else>
                                                    {{ `${data.item.name},` }}
                                                </div>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar
                                                    v-if="value === 'users'"
                                                >
                                                    <v-avatar
                                                        size="35"
                                                        color="secondary"
                                                        class="mx-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                !data.item
                                                                    .picture
                                                            "
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                avatarNick(
                                                                    data.item
                                                                        .name,
                                                                    data.item
                                                                        .lastname
                                                                )
                                                            }}
                                                        </span>
                                                        <img
                                                            :src="`${data.item.picture}`"
                                                            v-else
                                                        />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-avatar v-else>
                                                    <v-avatar
                                                        size="35"
                                                        color="#445D75"
                                                    >
                                                        <span
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                data.item.shortName.toUpperCase()
                                                            }}
                                                        </span>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <!-- === === CONTENT === === -->
                                                <!-- === USERS ===  -->
                                                <v-list-item-content
                                                    v-if="value === 'users'"
                                                >
                                                    <v-list-item-title>
                                                        {{ data.item.lastname }}
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.job }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <!-- === DEPARTMENTS === -->
                                                <v-list-item-content v-else>
                                                    <v-list-item-title>
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-tabs
                                                slot="prepend-item"
                                                centered
                                                class="elevation-1 select--fields"
                                            >
                                                <v-tab
                                                    @click="value = 'users'"
                                                    >{{
                                                        $t('service-info:users')
                                                    }}</v-tab
                                                >
                                                <v-tab
                                                    @click="
                                                        value = 'departments'
                                                    "
                                                    >{{
                                                        $t(
                                                            'service-info:departments'
                                                        )
                                                    }}</v-tab
                                                >
                                            </v-tabs>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="getServiceInfosModal.info"
                                            :label="`${$t(
                                                'service-info:info'
                                            )}*`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            :error-messages="
                                                getServiceInfosErrors.stepOne
                                                    .fields.info
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
            value: 'users',
            test: '',
        }
    },
    computed: {
        ...mapGetters([
            'getAllDepartments',
            'getServiceInfosModal',
            'getServiceInfosErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
            'getUsersList',
            'getProfileDetails',
        ]),
    },
    methods: {
        ...mapActions(['createRepresentative', 'fetchRepresentativesInput']),
        toMe() {
            this.getServiceInfosModal.to.users = [
                {
                    _id: this.getProfileDetails._id,
                    name: this.getProfileDetails.name,
                    lastname: this.getProfileDetails.lastname,
                    mail: this.getProfileDetails.mail,
                },
            ]
        },
        toAllUsers() {
            this.getServiceInfosModal.to.users = this.getUsersList
        },
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                )
            else if (item.fullName) {
                return item.fullName
                    .toLowerCase()
                    .includes(queryText.toLowerCase())
            } else
                return item.name.toLowerCase().includes(queryText.toLowerCase())
        },
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0]
            return nick.toUpperCase()
        },
    },
}
</script>
