<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getServiceInfosRegistry.name"
                    :avatarData="getServiceInfosRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :title="getServiceInfosRegistry.name"
                    :items="getServiceInfosTable.items"
                    :headers="headers"
                    :length="getServiceInfosTable.pages"
                    :page="getServiceInfosTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getServiceInfosSearchFields.searchFields"
                    :loading="getServiceInfosTable.loading"
                >
                    <Columns
                        slot="columns"
                        :items="getServiceInfosTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('service-info:additionService')"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addInfo()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('offers:addOffer') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import store from '../../../store/index'
import Columns from '../../../components/Registries/Service-info/Table/Columns.vue'
import AEContent from '../../../components/Registries/Service-info/Modal/Content.vue'
import Buttons from '../../../components/Registries/Service-info/Modal/Buttons.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('service-info:creationDate'),
                    value: 'creationDate',
                    width: '9%',
                },
                {
                    text: this.$t('service-info:title'),
                    value: 'title',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('service-info:creator'),
                    value: 'createdBy.name join createdBy.lastname',
                    width: '12%',
                    align: 'left',
                    sortable: false,
                    showSearch: true,
                },
                {
                    text: this.$t('service-info:responsible'),
                    value: 'responsible.name join responsible.lastname',
                    width: '12%',
                    align: 'left',
                    showSearch: true,
                },
                {
                    text: this.$t('service-info:priority'),
                    value: 'priority.field',
                    width: '8%',
                    align: 'left',
                    showSearch: true,
                },
                {
                    text: this.$t('global:status'),
                    value: 'status.name',
                    width: '100px',
                    align: 'left',
                    showSearch: true,
                },

                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getServiceInfosTable',
            'getServiceInfosSearchFields',
            'getServiceInfosRegistry',
            'getProfileDetails',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchServiceInfosTable',
            'fetchAllDepartments',
            'fetchUsers',
        ]),
        ...mapMutations([
            'clearServiceInfosModal',
            'clearServiceInfosErrors',
            'setServiceInfosTable',
            'setServiceInfosSearch',
            'clearServiceInfosTable',
        ]),
        closeModal() {
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addInfo() {
            await void this.fetchAllDepartments({ select: 'users' })
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearServiceInfosErrors()
            this.clearServiceInfosModal()
        },
        setSearchFields(searchFields) {
            this.setServiceInfosSearch(searchFields)
        },
        setSearch(search) {
            this.setServiceInfosTable({ search, page: 1 })
            this.fetchServiceInfosTable()
        },
        setPage(page) {
            this.setServiceInfosTable({ page })
            this.fetchServiceInfosTable()
        },
        setSort(sort) {
            this.setServiceInfosTable({ sort })
            this.fetchServiceInfosTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearServiceInfosTable()
            this.fetchServiceInfosTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/service-info/:id')
            store.commit('clearServiceInfosTable')
        store.dispatch('fetchServiceInfosTable').then(() => next())
    },
}
</script>
