var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,class:`${_vm.isStatusClosed(item) ? 'disabled' : ''}`},[_c('td',{staticClass:"truncate"},[_c('DoubleLine',{attrs:{"firtstLine":item.createdAt
                        ? _vm.$moment(item.createdAt).format(`DD-MM-YYYY`)
                        : _vm.$t('global:empty'),"secondLine":item.createdAt
                        ? _vm.$moment(item.createdAt).format(`HH:mm:SS`)
                        : _vm.$t('global:empty')}})],1),_c('td',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.$get(item, 'title', ''))+" ")]),_c('td',{staticClass:"truncate"},[_vm._v(" "+_vm._s(`${_vm.$get(item.createdBy, 'name', 'Brak')} ${_vm.$get( item.createdBy, 'lastname', '' )}`)+" ")]),(item.responsible && item.responsible.length > 0)?_c('td',{staticClass:"truncate"},_vm._l((item.responsible),function(el,index){return _c('span',{key:el._id},[_vm._v(" "+_vm._s(`${index > 0 ? ', ' : ''}${_vm.$get( el, 'name', 'Brak' )} ${_vm.$get(el, 'lastname', '')}`)+" ")])}),0):_c('td',[_c('span',[_vm._v(_vm._s(`${_vm.$t('global:empty')}`))])]),_c('td',[_c('v-chip',{style:(`color: ${_vm.$get(
                    item,
                    'priority.textColor',
                    '#FFFFFF'
                )}`),attrs:{"color":_vm.$get(item, 'priority.backgroundColor', '#555555'),"dark":"","label":"","small":""}},[_vm._v(_vm._s(_vm.$get(item, 'priority.field', _vm.$t('users:empty'))))])],1),_c('td',[_c('v-chip',{style:(`color: ${_vm.$get(
                    item,
                    'status.color.font',
                    '#FFFFFF'
                )}`),attrs:{"color":_vm.$get(item, 'status.color.background', '#555555'),"dark":"","label":"","small":""}},[_vm._v(_vm._s(_vm.$get(item, 'status.name', _vm.$t('users:empty'))))])],1),_c('td',{staticClass:"d-flex align-center justify-end"},[(_vm.hasFile(item.folders))?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")]):_vm._e(),(_vm.remove())?_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"iconGray","small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openConfirmModal(item._id)}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Usuń")])],1)],1)],1)],1):_vm._e(),_c('v-btn',{attrs:{"small":"","router":"","to":`/service-info/${item._id}`,"icon":""}},[_c('v-icon',{attrs:{"color":"secondary","small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1)])}),_c('ConfirmModal',{attrs:{"openConfirm":_vm.openConfirm},on:{"close-confirm":_vm.closeConfirmModal}},[_c('div',{attrs:{"slot":"main"},slot:"main"},[_c('ConfirmContent')],1),_c('div',{attrs:{"slot":"buttons"},slot:"buttons"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"white--text mx-2",attrs:{"color":"rgb(117, 117, 117)","width":"110","height":"40"},on:{"click":function($event){return _vm.closeConfirmModal()}}},[_vm._v(_vm._s(_vm.$t('global:cancel')))]),_c('v-btn',{staticClass:"white--text mx-2",attrs:{"width":"110","height":"40","color":"#445d75"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(_vm._s(_vm.$t('global:remove')))])],1)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }